import { create } from "zustand";

type LocationStore = {
    state?: any;
    setState: (stateValue?: any) => void;
}

export const useLocationStore = create<LocationStore>(
    (set) => ({
        setState: (stateValue?: any) => set({ state: stateValue })
    })
);