import { create } from "zustand";
import { ChatMessage, Conversation } from "../api";

type ConversationStore = {
    conversation: Conversation | null;
    setConversation: (newConversation: Conversation | null) => void;
    setTitle: (newTitle: string) => void;
    setMessages: (newMessages: ChatMessage[]) => void;
}

export const useConversationStore = create<ConversationStore>(
    (set) => ({
        conversation: null,
        setConversation: (newConversation: Conversation | null) => set({ conversation: newConversation }),
        setTitle: (newTitle: string) => {
            set(
                (state) => ({ conversation: state.conversation ? { ...state.conversation, title: newTitle } : state.conversation })
            )
        },
        setMessages: (newMessages: ChatMessage[]) => set(
            (state) => ({ conversation: state.conversation ? { ...state.conversation, messages: newMessages } : state.conversation })
        ),
    })
);