import styles from "./FollowUpQuestions.module.css";

import { MutableRefObject } from "react";

import { useChatStore } from "../../store/Chat.store";

import IconQuestionInputFollowUpChevronUp from "../common/IconQuestionInputFollowUpChevronUp";

import { useBoolean } from "@fluentui/react-hooks";
import { Callout, DefaultButton, DirectionalHint } from "@fluentui/react";

interface FollowUpQuestionsProps {
    contextMenuRef: MutableRefObject<null>;
    questions: string[];
};

const FollowUpQuestions: React.FunctionComponent<FollowUpQuestionsProps> = ({ contextMenuRef, questions }) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const updateUserPrompt = (newUserPrompt: string) => {
        useChatStore.setState({ userPrompt: newUserPrompt });
    };

    return (
        <div className={styles["follow-up-question"]}>
            <div style={{ display: "flex", alignItems: "center", alignSelf: "end", height: "32px" }}>
                <DefaultButton
                    styles={{
                        root: { minWidth: "110px", height: "30px", padding: "0px 5px", borderRadius: "20px", backgroundColor: "rgba(145, 158, 171, 0.24)", border: "none" },
                        flexContainer: { gap: "5px", flexDirection: 'row-reverse' },
                        textContainer: { flexGrow: "0" },
                        label: { fontWeight: 400, fontSize: "12px", lineHeight: "18px", color: "#1c252e", margin: "0px" }
                    }}
                    text={`${questions.length} follow-ups`}
                    onRenderIcon={() => { return (<IconQuestionInputFollowUpChevronUp />); }}
                    onClick={toggleIsCalloutVisible}
                />
            </div>
            {
                isCalloutVisible &&
                <Callout
                    className={styles["follow-up-question__contextual-menu"]}
                    target={contextMenuRef}
                    isBeakVisible={false}
                    gapSpace={10}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHintFixed={true}
                    directionalHint={DirectionalHint.topLeftEdge}
                    alignTargetEdge={true}
                    styles={{ calloutMain: { padding: "4px", borderRadius: "12px", display: "flex", flexDirection: "column", gap: "4px" } }}
                >
                    {
                        questions.map(question =>
                            <div
                                className={styles["follow-up-question__contextual-menu-item"]}
                                onClick={() => { toggleIsCalloutVisible(); updateUserPrompt(question); }}
                            >
                                {question}
                            </div>
                        )
                    }
                </Callout>
            }
        </div>
    );
};

export default FollowUpQuestions;