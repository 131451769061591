import styles from "./TenantPanel.module.css";

import { useNavigate } from "react-router-dom";

import { APP_ROUTE_ROOT } from "../../constants/routeNames";

interface TenantPanelProps {
    logoUrl?: string;
    name: string;
    madisonAiUrl: string;
};

const TenantPanel: React.FunctionComponent<TenantPanelProps> = ({ logoUrl, name, madisonAiUrl }) => {
    const navigate = useNavigate();

    const handleNavigateToRoot = () => {
        navigate(APP_ROUTE_ROOT);
        return;
    };

    return (
        <div className={styles["tenant-panel"]}>
            <div className={styles["tenant-panel__logo-container"]} onClick={handleNavigateToRoot}>
                {
                    logoUrl?.length ?? 0 > 0 ?
                        <img className={styles["tenant-panel__logo"]} src={logoUrl} />
                        : <div className={styles["tenant-panel__logo-placeholder"]}></div>
                }
            </div>
            <div className={styles["tenant-panel__information"]}>
                <div className={styles["tenant-panel__name"]}>{name}</div>
                <div className={styles["tenant-panel__powered-by"]}>powered by <a className={`${styles["tenant-panel__madisonai-url"]} ${styles["tenant-panel__powered-by"]}`} href={madisonAiUrl} target="_blank">Madison AI</a></div>
            </div>
        </div>
    );
};

export default TenantPanel;