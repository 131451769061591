import { useEffect, useRef } from "react";
import mermaid from "mermaid";

interface MermaidDiagramProps {
  chart: string;
  delay?: number;
}

const rendererMermaid = {
  code: ({
    className,
    children,
  }: {
    className?: string;
    children: React.ReactNode;
  }) => {
    if (className !== "language-mermaid") {
      return (
        <pre>
          <code>{children}</code>
        </pre>
      );
    }

    const value = String(children);
    return <MermaidDiagram chart={value} delay={250} />;
  },
};

const MermaidDiagram = ({ chart, delay }: MermaidDiagramProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const updateMermaid = () => {
    if (chartRef.current) {
      mermaid.contentLoaded();
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.removeAttribute("data-processed");
    }

    if (!delay) {
      updateMermaid();
      return;
    }

    if (delay < 0) {
      throw new Error("Delay cannot be negative");
    }

    const timeout = setTimeout(updateMermaid, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [chart]);

  return (
    <div className="mermaid" ref={chartRef}>
      {chart}
    </div>
  );
};

export default MermaidDiagram;
export { rendererMermaid };
