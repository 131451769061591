import styles from "./FileUploadList.module.css";

import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppStateContext } from "../../state/AppProvider";

import { useConversationStore } from "../../store/Conversation.store";
import { useUploadedFileStore } from "../../store/UploadedFiles.store";

import {
    checkIndexStatus,
    Conversation,
    ConversationType,
    deleteFileFromAzureBlobStorage,
    updateConversation,
    UploadedFile,
    UploadedFileOrigin,
    UploadFileStatus,
    uploadFileToAzureBlobStorage
} from "../../api";

import IconFileUploaderLoading from "../common/IconFileUploaderLoading";
import IconFileUploaderTrash from "../common/IconFileUploaderTrash";

import { Separator } from "@fluentui/react";

import { APP_ROUTE_REPORT_GENERATOR } from "../../constants/routeNames";

interface FileUploadListProps {
    filesOrigin: UploadedFileOrigin;
    filterByIncludingId?: string;
};

const FileUploadList: React.FunctionComponent<FileUploadListProps> = ({ filesOrigin, filterByIncludingId }) => {
    const { pathname: appLocation } = useLocation();
    const appStateContext = useContext(AppStateContext);

    const currentConversation = useConversationStore((state) => state.conversation);
    const setCurrentConversation = useConversationStore((state) => state.setConversation);

    const intervalIds: number[] = [];

    const [processingFiles, setProcessingFiles] = useState<Set<string>>(new Set());
    const uploadedFiles = useUploadedFileStore((state) => state.files);
    const updateUploadedFileStatus = useUploadedFileStore((state) => state.updateFileStatus);
    const removeUploadedFile = useUploadedFileStore((state) => state.removeFile);
    const addUploadedFiles = useUploadedFileStore((state) => state.addNewFiles);

    useEffect(
        () => {
            return () => {
                intervalIds.forEach((id) => clearInterval(id));
            };
        },
        []
    );

    const checkFileIndexingStatus = async (filename: string) => {
        const updatedStatus = await checkIndexStatus(filename, appStateContext?.state.currentKnowledgeDomain?.indexName!);
        updateUploadedFileStatus(filename, updatedStatus);
    };

    const processFileUpload = async (file: UploadedFile) => {
        const uploadResult = await uploadFileToAzureBlobStorage(file.file!, filesOrigin, appStateContext?.state.currentKnowledgeDomain?.containerName!);
        removeUploadedFile(file.name);
        const newFiles = uploadResult.map((name) => ({ name, status: UploadFileStatus.Indexing, origin: filesOrigin }));
        addUploadedFiles(newFiles);
    };

    const deleteFile = async (file: UploadedFile) => {
        const deleteResult = await deleteFileFromAzureBlobStorage(file.name, filesOrigin, appStateContext?.state.currentKnowledgeDomain?.containerName!);
        if (deleteResult.ok || deleteResult.status == 404) {
            removeUploadedFile(file.name);
        }
        if (
            appLocation == APP_ROUTE_REPORT_GENERATOR
            && currentConversation
            && currentConversation.id
            && currentConversation.type == ConversationType.Report
            && currentConversation.associated_filenames
            && currentConversation.associated_filenames.some(x => x.includes(file.name))
        ) {
            const associatedFileNames = [...currentConversation.associated_filenames.filter(x => !x.includes(file.name))];
            const updatedConversation = { ...currentConversation, associated_filenames: associatedFileNames };
            setCurrentConversation(updatedConversation);
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: updatedConversation });
            try {
                const partialConversation: Partial<Conversation> = {
                    id: currentConversation.id,
                    associated_filenames: associatedFileNames
                };
                await updateConversation(partialConversation);
            } catch { }
        }
    };

    useEffect(
        () => {
            const filesInProgress = uploadedFiles.filter(
                (file) => file.status !== UploadFileStatus.Completed
                    && !processingFiles.has(file.name)
            );

            if (filesInProgress.length === 0) {
                return;
            }

            const interval = setInterval(
                async () => {
                    try {
                        const newProcessingFiles = new Set(processingFiles);

                        await Promise.all(
                            filesInProgress.map(
                                async (file) => {
                                    if (!newProcessingFiles.has(file.name)) {
                                        newProcessingFiles.add(file.name);
                                        setProcessingFiles(new Set(newProcessingFiles));

                                        try {
                                            if (file.status === UploadFileStatus.Indexing) {
                                                await checkFileIndexingStatus(file.name);
                                            } else if (file.status === UploadFileStatus.Uploading) {
                                                await processFileUpload(file);
                                            } else if (file.status === UploadFileStatus.Deleting) {
                                                await deleteFile(file);
                                            }
                                        } finally {
                                            newProcessingFiles.delete(file.name);
                                            setProcessingFiles(new Set(newProcessingFiles));
                                        }
                                    }
                                }
                            )
                        );
                    } catch (error) {
                        console.error("Error processing files:", error);
                    }
                },
                5000
            );

            intervalIds.push(interval);

            return () => clearInterval(interval);
        },
        [uploadedFiles, processingFiles]
    );

    const deleteUploadedFile = (file: UploadedFile) => {
        updateUploadedFileStatus(file.name, UploadFileStatus.Deleting);
    };

    return (
        <div className={`${styles["file-uploader-list"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-uploader-list--report"] : ""}`}>
            {
                uploadedFiles
                    .filter(
                        x => filesOrigin == UploadedFileOrigin.Chat
                            || (
                                filesOrigin == UploadedFileOrigin.Report
                                && x.origin == filesOrigin
                                && filterByIncludingId
                                && x.name.includes(filterByIncludingId)
                            )
                    )
                    .map(
                        (file, index) => {
                            return (
                                <div key={`file-uploader-list-item__${index}`} className={`${styles["file-uploader-list-item"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-uploader-list-item--report"] : ""}`}>
                                    <div className={`${styles["file-uploader-list-item__content-container"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-uploader-list-item__content-container--report"] : ""}`}>
                                        <div className={`${styles["file-uploader-list-item__content"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-uploader-list-item__content--report"] : ""}`}>
                                            <div className={styles["file-uploader-list-item__file-name"]}>{file.name}</div>
                                            {
                                                file.status !== UploadFileStatus.Completed &&
                                                <div className={styles["file-uploader-list-item__file-status"]}>{file.status}...</div>
                                            }
                                        </div>
                                        {
                                            file.status == UploadFileStatus.Completed
                                            && file.origin == filesOrigin
                                            && appStateContext?.state.isValidCurrentKnowledgeDomain
                                            && <div className={styles["file-uploader-list-item__icon-wrapper"]} onClick={() => deleteUploadedFile(file)}>
                                                <IconFileUploaderTrash className={`${styles["file-uploader-list-item__icon"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-uploader-list-item__icon--report"] : ""}`} />
                                            </div>
                                        }
                                        {
                                            file.status !== UploadFileStatus.Completed
                                            && <IconFileUploaderLoading className={`${styles["file-uploader-list-item__icon"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-uploader-list-item__icon--report"] : ""} ${styles["file-uploader-list-item__icon--loading"]}`} />
                                        }
                                    </div>
                                    {
                                        appLocation != APP_ROUTE_REPORT_GENERATOR
                                        && <Separator />
                                    }
                                </div>
                            )
                        }
                    )
            }
        </div>
    );
};

export default FileUploadList;