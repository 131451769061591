import styles from "./ChatHistory.module.css";

import { Conversation, historyList, KnowledgeDomain } from "../../api";

import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { AppStateContext } from "../../state/AppProvider";

import { Spinner, SpinnerSize } from "@fluentui/react";

import ChatHistoryListItem from "./ChatHistoryListItem";

import InfiniteScroll from "react-infinite-scroll-component";

const ChatHistory: React.FunctionComponent = () => {
    const appStateContext = useContext(AppStateContext);

    const [sortedChatHistory, setSortedChatHistory] = useState<Conversation[]>([]);
    const previousKnowledgeDomainRef = useRef<KnowledgeDomain | null>();
    const historyListRef = useRef<HTMLDivElement>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const handleFetchHistory = useCallback(
        async () => {
            const previous = previousKnowledgeDomainRef.current;
            previousKnowledgeDomainRef.current = appStateContext?.state.currentKnowledgeDomain;

            const currentChatHistory =
                previous?.id !== appStateContext?.state.currentKnowledgeDomain?.id
                    ? []
                    : appStateContext?.state.chatHistory ?? [];

            await historyList(currentChatHistory.length, appStateContext?.state.currentKnowledgeDomain?.id!)
                .then(
                    (response) => {
                        const responseChatHistory = response ?? [];

                        if (responseChatHistory.length < 1) {
                            setHasMore(false);
                        }

                        const concatenatedChatHistory = [...currentChatHistory, ...responseChatHistory];

                        appStateContext?.dispatch({ type: "FETCH_CHAT_HISTORY", payload: concatenatedChatHistory });
                    }
                )
                .catch(
                    () => {
                        appStateContext?.dispatch({ type: "FETCH_CHAT_HISTORY", payload: [] });
                        setHasMore(false);
                    }
                );
        },
        [
            appStateContext?.state.currentKnowledgeDomain?.id,
            appStateContext?.state.chatHistory,
        ]
    );

    useEffect(
        () => {
            if (appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
                handleFetchHistory();
            }
        },
        [appStateContext?.state.isCosmosDBAvailable.cosmosDB, appStateContext?.state.currentKnowledgeDomain?.id]
    );

    useEffect(
        () => {
            const handleScroll = () => {
                const historyList = historyListRef.current;
                if (!historyList) {
                    return;
                }

                const isBottom =
                    historyList.scrollHeight - historyList.scrollTop <= historyList.clientHeight + 1;

                if (isBottom) {
                    handleFetchHistory();
                }
            };

            const historyList = historyListRef.current;

            if (historyList) {
                historyList.addEventListener("scroll", handleScroll);
            }

            return () => {
                if (historyList) {
                    historyList.removeEventListener("scroll", handleScroll);
                }
            };
        },
        [handleFetchHistory]
    );

    useEffect(
        () => {
            const currentChatHistory = appStateContext?.state.chatHistory ?? [];
            const sortedConversationsList = currentChatHistory
                .sort(
                    (a, b) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        return dateB.getTime() - dateA.getTime();
                    }
                );
            setSortedChatHistory(sortedConversationsList);

            if (sortedConversationsList.length < 1) {
                setHasMore(false);
            }
        },
        [appStateContext?.state.chatHistory]
    );

    return (
        <div className={styles["chat-history"]}>
            <div id="chatHistoryListDiv" className={styles["chat-history__list"]}>
                <InfiniteScroll
                    style={{ overflowX: "hidden" }}
                    dataLength={sortedChatHistory.length}
                    next={handleFetchHistory}
                    hasMore={hasMore}
                    loader={<div className={styles["chat-history__spinner"]}><Spinner size={SpinnerSize.small} aria-label="loading more chat history" /></div>}
                    scrollableTarget="chatHistoryListDiv"
                >
                    {
                        sortedChatHistory.map(
                            conversation => (
                                <ChatHistoryListItem conversation={conversation} />
                            )
                        )
                    }
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default ChatHistory;