import { Spinner, SpinnerSize } from "@fluentui/react";

const RouteLoader: React.FunctionComponent = () => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flex: "1 1 auto", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Spinner size={SpinnerSize.large} />
        </div>
    );
};

export default RouteLoader;