import styles from "./StaffReport.module.css";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import { AppStateContext } from "../../state/AppProvider";

import { useChatStore } from "../../store/Chat.store";
import { useConversationStore } from "../../store/Conversation.store";
import { ReportSectionSelection, useReportSectionStore } from "../../store/ReportSection.store";
import { useLocationStore } from "../../store/Location.store";

import {
    Citation, Conversation, ConversationType, CosmosDBStatus, ErrorMessage,
    generateReport, regenerateSection, ReportHeader, ReportHeaderType,
    ReportRequest, StreamingStatus, updateReportCosmosDB
} from "../../api";

import { Dialog, DialogType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import ReactMarkdown from "react-markdown";
import { XSSAllowTags } from "../../constants/xssAllowTags";
import DOMPurify from "dompurify";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub';

import { APP_ROUTE_REPORT_GENERATOR, APP_ROUTE_ROOT } from "../../constants/routeNames";

import { parseAnswer } from "../../components/Answer/AnswerParser";
import { RIGHT_PANE_TAB_CITATIONS_NAME, RIGHT_PANE_TAB_SOURCES_NAME } from "../../constants/rightPane";

interface StaffReportSectionProps {
    answer: string;
    citations: Citation[];
    onCitationClicked: (citedDocument: Citation) => void;
};

const StaffReportSection = React.memo(
    ({ answer, citations, onCitationClicked }: StaffReportSectionProps) => {
        const parsedAnswer = useMemo(
            () => parseAnswer(
                { answer, citations },
                /\[((?:docs?[-_]?\d+(?:[-_]\d+)*)|(?:doc_\d+)|(?:\d+))\]/g,
                5
            ),
            [answer, citations]
        );

        const selectCitation = (citationReIndexId: string) => {
            const reindexId = citationReIndexId.split('-')[0];
            const citation = parsedAnswer.citations.find(({ reindex_id }) => reindex_id === reindexId);

            if (!citation) {
                return;
            }

            onCitationClicked(citation);
        };

        return (
            <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm, supersub]}
                components={{
                    a(props) {
                        if (props.href === "#citation") {
                            return <a
                                className={styles["staff-report__section-citation"]}
                                onClick={() => selectCitation(props.children[0]?.toString()!)}
                            >
                                {props.children[0]?.toString().split('-')[0]}
                            </a>
                        }
                        return <a
                            target="_blank"
                            rel="noreferrer"
                            {...props}
                        />
                    },
                    table: ({ node, ...props }) => (
                        <div style={{ overflowX: 'auto', width: '100%' }}>
                            <table {...props} />
                        </div>
                    ),
                }}
            >
                {DOMPurify.sanitize(parsedAnswer.markdownFormatText, { ALLOWED_TAGS: XSSAllowTags })}
            </ReactMarkdown>
        );
    }
);

const StaffReport: React.FunctionComponent = () => {
    const setLocationState = useLocationStore((state) => state.setState);

    const navigate = useNavigate();

    const appStateContext = useContext(AppStateContext);
    const tenantSettings = appStateContext?.state.tenantSettings;

    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [reportHeader, setReportHeader] = useState<ReportHeader[]>([]);

    const currentConversation = useConversationStore((state) => state.conversation);
    const setCurrentConversation = useConversationStore((state) => state.setConversation);

    const reportSections = useReportSectionStore((state) => state.sections);
    const setReportSections = useReportSectionStore((state) => state.setReportSections);
    const setOnRephraseReportSection = useReportSectionStore((state) => state.setOnRephraseReportSection);
    const setOnStopReportSectionGeneration = useReportSectionStore((state) => state.setOnStopReportSectionGeneration);
    const streamingStatus = useReportSectionStore((state) => state.streamingStatus);
    const setStreamingStatus = useReportSectionStore((state) => state.setStreamingStatus);
    const reportCitations = useReportSectionStore((state) => state.citations);
    const setReportCitations = useReportSectionStore((state) => state.setReportCitations);

    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const sectionRefs = useRef<HTMLDivElement[]>([]);

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };
    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const generateReportHeader = (reportRequest: ReportRequest): ReportHeader[] => {
        const template = JSON.parse(reportRequest.template.definition);
        const headerTemplate = template && template.header ? template.header as ReportHeader[] : [];
        const reportHeader: ReportHeader[] = headerTemplate.map(
            header => {
                const headerLabel: string = header.label ? `${header.label}: ` : "";
                let headerValue: string = header.value ?? "";
                switch (header.type) {
                    case ReportHeaderType.Date:
                        const currentDate = new Date().toISOString();
                        headerValue = currentDate.split("T")[0];
                        break;
                    case ReportHeaderType.Subject:
                        headerValue = reportRequest.intent
                        break;
                }
                const newReportHeader: ReportHeader = {
                    type: header.type,
                    value: `${headerLabel}${headerValue}`,
                };
                return newReportHeader;
            }
        );
        return reportHeader;
    };

    const generateStaffReport = async (reportRequest: ReportRequest) => {
        const controller = abortControllerRef.current;

        // Track the event using the Fathom global object
        if (window.fathom) {
            window.fathom.trackEvent('staff_report_query_submitted', { eventData: { source: 'send_button' } });
        }

        try {
            setStreamingStatus(StreamingStatus.Processing);

            const reportHeader = generateReportHeader(reportRequest);

            const report: Conversation = {
                id: reportRequest.id!,
                report_sections: [],
                date: new Date().toISOString(),
                title: reportRequest.intent,
                knowledgeDomain: reportRequest.knowledgeDomain,
                type: ConversationType.Report,
                report_template: reportRequest.template,
                associated_filenames: reportRequest.associatedFileNames,
                report_header: reportHeader
            };

            setCurrentConversation(report);
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: report });

            setReportHeader(reportHeader);

            const response = await generateReport(controller.signal, reportRequest);
            const reader = response.body!.getReader();
            processBuffer(reader, report);
        } catch (err) {
            console.log(err);
        } finally {
            setStreamingStatus(StreamingStatus.Done);
        }
    }

    const processBuffer = async (reader: any, report: any, isRephrasing: boolean = false) => {
        let runningText = "";
        while (true) {
            const { done, value } = await reader?.read();

            if (done) {
                if (!isRephrasing) {
                    const citations = useReportSectionStore.getState().citations;
                    await completeReportProcessing(report.id, report.report_header, citations);
                    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
                } else {
                    await completeReportProcessing(report.id);
                }

                break;
            }

            var buffer = new TextDecoder("utf-8").decode(value, { stream: true });
            const objects = buffer.split("\n");
            objects.forEach((obj) => {
                try {
                    if (obj !== "" && obj !== "{}") {
                        runningText += obj;
                        const report_obj = JSON.parse(runningText);

                        const responseCitations = report_obj.citations;
                        if (responseCitations && Array.isArray(responseCitations)) {
                            const citations = responseCitations as Citation[];
                            if (citations.length > 0) {
                                setReportCitations(citations);
                            }
                        }

                        const sections = report_obj.sections as ReportSectionSelection[];
                        setReportSections(sections);

                        if (!isRephrasing) {
                            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
                        }
                        runningText = ""
                    }
                } catch (e) {
                    if (!(e instanceof SyntaxError)) {
                        console.error(e);
                        throw e;
                    } else {
                        // console.log("Incomplete message. Continuing...")
                    }
                }
            });
        }
    }

    const completeReportProcessing = async (reportId: string, header?: ReportHeader[], citations?: Citation[]) => {
        const sections = useReportSectionStore.getState().sections;

        if (appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
            await updateReportCosmosDB({
                id: reportId!,
                header: header,
                sections: sections,
                citations: citations,
            });
        }

        const currentReport = useConversationStore.getState().conversation;

        if (currentReport) {
            let updatedReport: Conversation = { ...currentReport, report_sections: sections };
            if (header && header.length > 0) {
                updatedReport.report_header = header;
            }
            if (citations && citations.length > 0) {
                updatedReport.report_citations = citations;
            }
            setCurrentConversation(updatedReport);
        }
    }

    const rephraseReportSection = useCallback(
        async (instruction: string, reportId?: string) => {
            const currentReportSections = useReportSectionStore.getState().sections;
            const selectedSection = currentReportSections.find(section => section.selected);

            if (!selectedSection) {
                return;
            }

            const controller = abortControllerRef.current;

            const reportDefinition = currentConversation?.report_template?.definition;
            const reportInstructions = JSON.parse(reportDefinition!).report_instructions;

            try {
                setStreamingStatus(StreamingStatus.Processing);

                const response = await regenerateSection(
                    controller.signal,
                    {
                        reportInstructions: reportInstructions,
                        reportSections: currentReportSections,
                        reportIntent: currentConversation?.title!,
                        sectionId: selectedSection.id,
                        newSectionInstruction: instruction,
                        knowledgeDomain: currentConversation?.knowledgeDomain!,
                    }
                );

                const reader = response.body!.getReader();
                processBuffer(reader, { id: reportId! }, true);
            } catch (err) {
                console.log(err);
            } finally {
                setStreamingStatus(StreamingStatus.Done);
            }
        },
        [currentConversation]
    );

    useEffect(
        () => {
            setOnRephraseReportSection(rephraseReportSection);
        },
        [rephraseReportSection]
    );

    const abortControllerRef = useRef(new AbortController());
    const signalControllerToAbort = () => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    };

    const resetReportGeneration = () => {
        navigate(APP_ROUTE_REPORT_GENERATOR);
    };
    const resetReportSectionGeneration = () => {
        setCurrentConversation(currentConversation);
    };

    const stopReportProcessing = () => {
        signalControllerToAbort();

        if (!currentConversation?.report_sections || currentConversation.report_sections.length < 1) {
            // Stop report generation
            resetReportGeneration();
        } else {
            // Stop section rephrasing
            resetReportSectionGeneration();
        }
    };

    useEffect(
        () => {
            appStateContext?.dispatch({ type: "SET_IS_ROUTE_LOADING", payload: false });

            signalControllerToAbort();

            const locationState = useLocationStore.getState().state;
            setLocationState();

            let reportRequest: ReportRequest = locationState?.reportRequest;

            if (!reportRequest && !currentConversation) {
                navigate(APP_ROUTE_ROOT);
                return;
            }

            appStateContext?.dispatch({ type: "SET_HIDDEN_RIGHT_PANE", payload: false });
            appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: RIGHT_PANE_TAB_SOURCES_NAME });
            if (!appStateContext?.state.isMobileView) {
                appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: true });
            }

            setOnRephraseReportSection(rephraseReportSection);
            setOnStopReportSectionGeneration(stopReportProcessing);

            if (reportRequest && reportRequest.intent && reportRequest.knowledgeDomain && reportRequest.template) {
                generateStaffReport(reportRequest);
            } else if (currentConversation) {
                if (!currentConversation.report_sections || currentConversation.report_sections.length < 1) {
                    navigate(APP_ROUTE_REPORT_GENERATOR);
                    return;
                }

                fillReport(currentConversation);
            }

            return () => {
                signalControllerToAbort();
                appStateContext?.dispatch({ type: "SET_ACTIVE_CITATION", payload: null });
                appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: RIGHT_PANE_TAB_SOURCES_NAME });
                appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: false });
                setOnStopReportSectionGeneration();
                setOnRephraseReportSection();
                setStreamingStatus(StreamingStatus.NotRunning);
                setReportCitations([]);
                setReportHeader([]);
                setReportSections([]);
            };
        },
        []
    );

    const fillReport = (conversation: Conversation) => {
        setReportHeader(conversation.report_header ?? []);

        const currentConversationReportSections = conversation.report_sections ?? [];
        const sectionsWithSelection: ReportSectionSelection[] = currentConversationReportSections.map(
            section => ({
                ...section,
                id: section.id ?? uuid(),
                selected: false,
            })
        );
        setReportSections(sectionsWithSelection);

        setReportCitations(conversation.report_citations ?? []);
    };

    useEffect(
        () => {
            appStateContext?.dispatch({ type: "SET_IS_ROUTE_LOADING", payload: false });
            appStateContext?.dispatch({ type: "SET_ACTIVE_CITATION", payload: null });
            appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: RIGHT_PANE_TAB_SOURCES_NAME });

            if (currentConversation?.id) {
                fillReport(currentConversation);
            }
        },
        [currentConversation?.id]
    );

    useEffect(
        () => {
            if (
                tenantSettings?.visibility.history
                && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working
                && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
                && hideErrorDialog
            ) {
                let subtitle = `${appStateContext?.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
                setErrorMsg({
                    title: "Chat history is not enabled",
                    subtitle: subtitle
                })
                toggleErrorDialog();
            }
        },
        [appStateContext?.state.isCosmosDBAvailable]
    );

    const handleErrorDialogClose = () => {
        toggleErrorDialog();
        setTimeout(
            () => {
                setErrorMsg(null);
            },
            500
        );
    };

    const handleSectionClick = (id: string, index: number) => {
        if (streamingStatus == StreamingStatus.Processing) {
            return;
        }

        const currentReportSections = [...reportSections];
        setReportSections(
            currentReportSections.map(
                section => {
                    if (section.id === id) {
                        return { ...section, selected: !section.selected };
                    }
                    return { ...section, selected: false };
                }
            )
        );
        sectionRefs.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: true });
        useChatStore.setState({ userPrompt: "" });
    };

    const onShowCitation = useCallback(
        (citation: Citation) => {
            if (streamingStatus == StreamingStatus.Processing) {
                return;
            }

            appStateContext?.dispatch({ type: "SET_ACTIVE_CITATION", payload: citation });
            appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: RIGHT_PANE_TAB_CITATIONS_NAME });
            appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: true });
        },
        [streamingStatus]
    );

    return (
        <div className={styles["staff-report"]}>
            <Dialog
                hidden={hideErrorDialog}
                onDismiss={handleErrorDialogClose}
                dialogContentProps={errorDialogContentProps}
                modalProps={modalProps}
            >
            </Dialog>
            <div className={`${styles["staff-report__body"]} ${streamingStatus == StreamingStatus.Processing ? styles["staff-report__body--non-selectable"] : ""}`}>
                <div className={styles["staff-report__header"]}>
                    {
                        reportHeader.map(
                            header => {
                                return (
                                    <div className={styles["staff-report__header-text"]}>
                                        {
                                            header.type === ReportHeaderType.Logo ? <img src={header.value} width={80} height={80} /> : header.value
                                        }
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <div className={styles["staff-report__sections"]}>
                    {
                        reportSections
                            .map(
                                (section, index) => {
                                    return (
                                        <div
                                            key={`staff-report__section__${section.id}`}
                                            ref={(el) => (sectionRefs.current[index] = el!)}
                                            className={`${styles["staff-report__section"]} ${section.selected ? styles["staff-report__section--selected"] : ""}`}
                                            onClick={() => handleSectionClick(section.id, index)}
                                        >
                                            <div className={`${styles["staff-report__section-text"]} ${styles["staff-report__section-text--heading"]}`}>{section.title}</div>
                                            <div className={`${styles["staff-report__section-text"]} ${styles["staff-report__section-text--content"]}`}>
                                                <StaffReportSection answer={section.content} citations={reportCitations} onCitationClicked={onShowCitation} />
                                            </div>
                                        </div>
                                    );
                                }
                            )
                    }
                    <div ref={chatMessageStreamEnd} />
                </div>
            </div>
        </div>
    );
};

export default StaffReport;