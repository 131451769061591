export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    message_id?: string;
    feedback?: Feedback;
};

export type CitationCaption = {
    text: string;
    highlights: string;
};

export type Citation = {
    original_index?: number;
    origin: string;
    part_index?: number;
    content: string;
    id: string;
    title: string | null;
    category: string | null;
    filepath: string | null;
    url: string | null;
    date: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
    captions?: CitationCaption[];
};

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
    rewritten_query: string;
};

export type WebBrowserToolMessageContent = {
    citations: string[];
};

export type ChatMessage = {
    id: string;
    date: string;

    role: string;
    content: string;
    webBrowserContent?: string;

    citations?: Citation[];
    webBrowserCitations?: Citation[];

    feedback?: Feedback;

    context?: string;
    metadata?: {
        rewrittenQuery?: string;
    }

    fullResponseContent?: string;
    doneResponseContent?: boolean;
    fullWebResponseContent?: string;
    doneWebResponseContent?: boolean;
};

export enum ConversationType {
    Conversation = "conversation",
    Report = "report",
};

export enum ReportHeaderType {
    Text = "text",
    Date = "date",
    Logo = "logo",
    Subject = "subject",
};

export type ReportHeader = {
    type: ReportHeaderType;
    label?: string;
    value?: string;
};

export type ReportSection = {
    id: string;
    title: string;
    content: string;
};

export type Conversation = {
    id: string;
    title: string;
    messages?: ChatMessage[];
    date: string;
    type?: ConversationType;
    knowledgeDomain?: KnowledgeDomainDB;
    report_template?: ReportTemplateDB;
    report_header?: ReportHeader[];
    report_instructions?: string[];
    report_sections?: ReportSection[];
    associated_filenames?: string[];
    report_citations?: Citation[];
};

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk",
};

export type ChatResponseChoice = {
    messages: ChatMessage[];
};

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    followup_questions: string[];
    error?: any;
};

export type ConversationRequest = {
    conversationId?: string;
    knowledgeDomain: KnowledgeDomainDB;
    messages: ChatMessage[];
};

export type ReportRequest = {
    id?: string;
    intent: string;
    knowledgeDomain: KnowledgeDomainDB;
    template: ReportTemplateDB;
    associatedFileNames?: string[];
};

export type UpdateReportRequest = {
    id: string;
    header?: ReportHeader[];
    sections: ReportSection[];
    citations?: Citation[];
};

export type RegenerateSectionRequest = {
    reportInstructions: string[];
    reportSections: ReportSection[];
    reportIntent: string;
    sectionId: string;
    newSectionInstruction: string;
    knowledgeDomain: KnowledgeDomainDB;
}

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    InvalidCredentials = "CosmosDB has invalid credentials",
    InvalidDatabase = "Invalid CosmosDB database name",
    InvalidContainer = "Invalid CosmosDB container name",
    Working = "CosmosDB is configured and working",
};

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string,
};

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted",
};

export type ErrorMessage = {
    title: string;
    subtitle: string;
};

export type UI = {
    chat_assistant_logo?: string;
};

export type FrontendSettings = {
    feedback_enabled?: string | null;
    ui?: UI;
};

export enum Feedback {
    Neutral = "neutral",
    Positive = "positive",
    Negative = "negative",
    MissingCitation = "missing_citation",
    WrongCitation = "wrong_citation",
    OutOfScope = "out_of_scope",
    InaccurateOrIrrelevant = "inaccurate_or_irrelevant",
    OtherUnhelpful = "other_unhelpful",
    HateSpeech = "hate_speech",
    Violent = "violent",
    Sexual = "sexual",
    Manipulative = "manipulative",
    OtherHarmful = "other_harmlful",
};

export type ReportTemplateDB = {
    id: string;
    name: string;
    definition: string;
};

export type ReportTemplate = {
    id: string;
    name: string;
    definition: string;
};

export type KnowledgeDomainDB = {
    id: string;
    label: string;
    description: string;
    tooltip?: string;
    iconUrl?: string;
    indexName: string;
    containerName: string;
}

export type KnowledgeDomain = {
    id: string;
    label: string; // Title
    description: string; // Description
    disabled?: boolean;
    tooltip?: string;
    iconUrl?: string;  // Icon URL
    indexName: string; // Index name
    containerName: string; // Blob container name
    reportTemplates?: ReportTemplate[];
    sharepointDirectory: string,
    sharepointInformationLink: string,
    topics: Topic[];
};

export type Topic = {
    id: number;
    title: string;
    description: string;
    userPrompt: string;
};

export type TenantSettings = {
    id: number;
    name: string;
    domain: string;
    header: {
        logoPath: string;
    };
    visibility: {
        fileUploader?: boolean;
        history?: boolean;
        reports?: boolean;
    };
    tooltip?: string;
    knowledgeDomains: KnowledgeDomain[];
};

export enum UploadFileStatus {
    Uploading = "Uploading",
    Indexing = "Indexing",
    Deleting = "Deleting",
    Completed = "Completed",
};

export enum UploadedFileOrigin {
    Chat = "chat",
    Report = "report"
};

export type UploadedFile = {
    name: string;
    status: UploadFileStatus;
    file?: File;
    splittedFilename?: string[];
    origin: UploadedFileOrigin;
};

export enum UploadedFilesLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    Empty = "empty",
};

export enum ExportFileFormat {
    PDF = "pdf",
    DOCX = "docx",
    TXT = "txt",
};

export const enum StreamingStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done",
};