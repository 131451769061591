const processStream = async function* (
  reader: ReadableStreamDefaultReader<Uint8Array>,
  decoder: TextDecoder | undefined = undefined,
  delimiter: string = "\n",
  ignoreEmptyLines: boolean = true
): AsyncGenerator<[string | undefined, boolean]> {
  if (!decoder) {
    decoder = new TextDecoder("utf-8");
  }

  let dynamicBuffer = "";

  while (true) {
    const { done, value } = await reader.read();

    if (value) {
      dynamicBuffer += decoder.decode(value, { stream: !done });
    }

    const lines = dynamicBuffer.split(delimiter);

    if (lines.length > 1) {
      // processes all lines except the last one (which may be incomplete).
      for (let i = 0; i < lines.length - 1; i++) {
        const currentLine = lines[i];

        if (currentLine.trim().length > 0 || !ignoreEmptyLines) {
          yield [currentLine, false];
        }
      }

      // saves the last line (possibly incomplete) for the next iteration
      dynamicBuffer = lines[lines.length - 1];
    }

    if (done) {
      yield [
        ignoreEmptyLines && dynamicBuffer.trim().length == 0
          ? undefined
          : dynamicBuffer,
        true,
      ];

      break;
    }
  }
};

export { processStream };
