import { ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, ExportFileFormat, RegenerateSectionRequest, ReportRequest, TenantSettings, UpdateReportRequest, UploadedFile, UploadedFileOrigin, UploadFileStatus, UserInfo } from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            knowledge_domain: options.knowledgeDomain,
            messages: options.messages,
        }),
        signal: abortSignal
    });

    return response;
};

export async function webSearchApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch(
        "/conversation_web_search",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                messages: options.messages
            }),
            signal: abortSignal
        }
    );

    return response;
};

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
};

export const fetchChatHistoryInit = (): Conversation[] | null => {
    return chatHistorySampleData;
};

export const historyList = async (offset = 0, knowledgeDomainId: string): Promise<Conversation[] | null> => {
    const response = await fetch(
        `/history/list?offset=${offset}&knowledge_domain_id=${knowledgeDomainId}`,
        {
            method: "GET",
        }
    )
        .then(
            async (res) => {
                const conversations: Conversation[] = await res.json();
                return conversations;
            }
        )
        .catch(
            () => {
                console.error("There was an issue fetching your data.");
                return null;
            }
        );
    return response;
};

export const historyRead = async (conversationId: string): Promise<Conversation | null> => {
    const response = await fetch(
        "/history/read",
        {
            method: "POST",
            body: JSON.stringify({ conversation_id: conversationId }),
            headers: { "Content-Type": "application/json" },
        }
    )
        .then(
            async (res) => {
                const conversation: Conversation = await res.json();
                return conversation;
            }
        )
        .catch(
            () => {
                console.error("There was an issue fetching your data.");
                return null;
            }
        );
    return response;
};

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> => {
    let body;
    if (options.conversationId && options.conversationId.length > 0) {
        body = JSON.stringify({
            conversation_id: options.conversationId,
            knowledge_domain: options.knowledgeDomain,
            messages: options.messages,
        });
    } else {
        body = JSON.stringify({
            knowledge_domain: options.knowledgeDomain,
            messages: options.messages,
        });
    }
    const response = await fetch(
        "/history/generate",
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: body,
            signal: abortSignal
        }
    )
        .catch(
            () => {
                if (!abortSignal.aborted) {
                    console.error("There was an issue fetching your data.");
                }
                return new Response;
            }
        );
    return response;
};

export const historyUpdate = async (messages: ChatMessage[], conversationId: string): Promise<Response> => {
    const response = await fetch(
        "/history/update",
        {
            method: "POST",
            body: JSON.stringify({
                conversation_id: conversationId,
                messages: messages,
            }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    )
        .then(
            async (res) => {
                return res;
            }
        )
        .catch(
            () => {
                console.error("There was an issue fetching your data.");
                let errRes: Response = {
                    ...new Response,
                    ok: false,
                    status: 500,
                };
                return errRes;
            }
        );
    return response;
};

export const historyDelete = async (conversationId: string): Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: conversationId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
};

export const historyDeleteAll = async (): Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
};

export const historyClear = async (conversationId: string): Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: conversationId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
};

export const historyRename = async (conversationId: string, title: string): Promise<Response> => {
    const response = await fetch(
        "/history/rename",
        {
            method: "POST",
            body: JSON.stringify({
                conversation_id: conversationId,
                title: title
            }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    )
        .then(
            (res) => {
                return res;
            }
        )
        .catch(
            () => {
                console.error("There was an issue fetching your data.");
                let errRes: Response = {
                    ...new Response,
                    ok: false,
                    status: 500,
                };
                return errRes;
            }
        );

    return response;
};

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", { method: "GET" })
        .then(
            async res => {
                let respJson = await res.json();
                let formattedResponse;
                if (respJson.message) {
                    formattedResponse = CosmosDBStatus.Working
                } else {
                    if (res.status === 500) {
                        formattedResponse = CosmosDBStatus.NotWorking
                    } else if (res.status === 401) {
                        formattedResponse = CosmosDBStatus.InvalidCredentials
                    } else if (res.status === 422) {
                        formattedResponse = respJson.error
                    } else {
                        formattedResponse = CosmosDBStatus.NotConfigured
                    }
                }
                if (!res.ok) {
                    return {
                        cosmosDB: false,
                        status: formattedResponse
                    }
                } else {
                    return {
                        cosmosDB: true,
                        status: formattedResponse
                    }
                }
            }
        )
        .catch(
            (err) => {
                console.error("There was an issue fetching your data.");
                return {
                    cosmosDB: false,
                    status: err
                }
            }
        );
    return response;
};

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
};

export const fetchTenantSettings = async (): Promise<TenantSettings | null> => {

    try {
        const response = await fetch("/tenant_settings", { method: "GET" });

        if (!response.ok) {
            throw new Error("There was an issue fetching the tenant settings.");
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching tenant settings:", error);
        return null;
    }
};

export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue logging feedback.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
};

export const uploadFileToAzureBlobStorage = async (file: File, fileOrigin: UploadedFileOrigin, containerName: string): Promise<Array<string>> => {
    const container = containerName.trim();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_origin", fileOrigin);
    formData.append("container_name", container);

    return fetch("/upload", { method: 'POST', body: formData, })
        .then(
            response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
        );
};

export const checkIndexStatus = async (filename: string, indexName: string): Promise<UploadFileStatus> => {
    const index = indexName.trim();
    return await fetch(
        `/check-index-status`,
        {
            cache: "no-cache",
            method: "POST",
            body: JSON.stringify({ filename: filename, index_name: index }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    )
        .then(
            response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
        );
};

export const deleteFileFromAzureBlobStorage = async (filename: string, fileOrigin: UploadedFileOrigin, containerName: string): Promise<Response> => {
    const container = containerName.trim();
    return await fetch(
        `/upload`,
        {
            method: "DELETE",
            body: JSON.stringify({
                filename: filename,
                file_origin: fileOrigin,
                container_name: container
            }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    );
};

export const fetchUploadedFiles = async (signal: AbortSignal, containerName: string, indexName: string): Promise<Array<UploadedFile>> => {
    const container = containerName.trim();
    const index = indexName.trim();
    return await fetch(
        `/uploaded-files?container_name=${container}&index_name=${index}`,
        {
            signal: signal,
            cache: "no-cache",
            method: "GET"
        }
    )
        .then(
            response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
        );
};

export const exportConversation = async (fileFormat: ExportFileFormat, conversation: Conversation): Promise<Response> => {
    return await fetch(
        `/export`,
        {
            method: "POST",
            body: JSON.stringify({ file_format: fileFormat, conversation }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    );
};

export const generateReport = async (signal: AbortSignal, reportRequest: ReportRequest): Promise<Response> => {
    const { definition, name } = reportRequest.template;
    const response = await fetch(
        "/report/generate",
        {
            signal: signal,
            method: "POST",
            body: JSON.stringify({
                id: reportRequest.id,
                intent: reportRequest.intent,
                template: { name, definition },
                knowledge_domain: reportRequest.knowledgeDomain,
                associated_filenames: reportRequest.associatedFileNames
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );

    if (!response.ok) {
        throw new Error(`Error fetching from /generate_report: ${response.status} ${response.statusText}`);
    }

    if (!response.body) {
        throw new Error('ReadableStream not yet supported or response body is null.');
    }

    return response;
};

export const updateReportCosmosDB = async (updateReportRequest: UpdateReportRequest): Promise<Response> => {
    const response = await fetch(
        "/history/update/report",
        {
            method: "POST",
            body: JSON.stringify({
                id: updateReportRequest.id,
                header: updateReportRequest.header,
                sections: updateReportRequest.sections,
                citations: updateReportRequest.citations,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );

    if (!response.ok) {
        throw new Error(`Error fetching from /history/update/report: ${response.status} ${response.statusText}`);
    }

    return response;
};

export const regenerateSection = async (signal: AbortSignal, regenerateSectionRequest: RegenerateSectionRequest): Promise<Response> => {
    return await fetch(
        "/report/regenerate-section",
        {
            signal: signal,
            method: "POST",
            body: JSON.stringify({
                report_intent: regenerateSectionRequest.reportIntent,
                report_instructions: regenerateSectionRequest.reportInstructions,
                report_sections: regenerateSectionRequest.reportSections,
                section_id: regenerateSectionRequest.sectionId,
                new_section_instruction: regenerateSectionRequest.newSectionInstruction,
                knowledge_domain: regenerateSectionRequest.knowledgeDomain,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
};

export const updateConversation = async (conversation: Partial<Conversation>, signal?: AbortSignal): Promise<Response> => {
    return await fetch(
        `/conversation`,
        {
            signal: signal,
            method: "PUT",
            body: JSON.stringify({
                conversation: conversation,
            }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    );
};