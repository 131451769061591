import { create } from "zustand";
import { Citation, ReportSection, StreamingStatus } from "../api";

export type ReportSectionSelection = ReportSection & {
    selected: boolean;
};

type ReportSectionStore = {
    sections: ReportSectionSelection[];
    setReportSections: (newSections: ReportSectionSelection[]) => void;
    onRephraseReportSection?: (instruction: string, reportId?: string) => Promise<void>;
    setOnRephraseReportSection: (onRephraseReportSection?: (instruction: string, reportId?: string) => Promise<void>) => void;
    onStopReportSectionGeneration?: () => void;
    setOnStopReportSectionGeneration: (onStopReportSectionGeneration?: () => void) => void;
    streamingStatus: StreamingStatus;
    setStreamingStatus: (newStatus: StreamingStatus) => void;
    citations: Citation[];
    setReportCitations: (newCitations: Citation[]) => void;
}

export const useReportSectionStore = create<ReportSectionStore>(
    (set) => ({
        sections: [],
        setReportSections: (newSections: ReportSectionSelection[]) => set({ sections: newSections }),
        setOnRephraseReportSection: (newOnRephraseReportSection?) => set({ onRephraseReportSection: newOnRephraseReportSection }),
        setOnStopReportSectionGeneration: (newOnStopReportSectionGeneration?) => set({ onStopReportSectionGeneration: newOnStopReportSectionGeneration }),
        streamingStatus: StreamingStatus.NotRunning,
        setStreamingStatus: (newStatus: StreamingStatus) => set({ streamingStatus: newStatus }),
        citations: [],
        setReportCitations: (newCitations: Citation[]) => set({ citations: newCitations }),
    })
);