import { Conversation } from '../api';

import { RIGHT_PANE_TAB_SOURCES_NAME } from '../constants/rightPane';

import { Action, AppState } from './AppProvider';

import { useChatStore } from "../store/Chat.store";
import { useConversationStore } from '../store/Conversation.store';

export const appStateReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case 'UPDATE_CHAT_HISTORY':
            const currentConversation = useConversationStore.getState().conversation;
            if (!state.chatHistory || !currentConversation) {
                return state;
            }
            const conversationIndex = state.chatHistory.findIndex(conversation => conversation.id === action.payload.id);
            if (conversationIndex > -1) {
                let updatedChatHistory = [...state.chatHistory];
                updatedChatHistory[conversationIndex] = currentConversation;
                return { ...state, chatHistory: updatedChatHistory };
            } else {
                return { ...state, chatHistory: [...state.chatHistory, action.payload as Conversation] };
            }
        case 'UPDATE_CHAT_TITLE':
            if (!state.chatHistory) {
                return { ...state, chatHistory: [] };
            }
            const updatedChats = state.chatHistory.map(
                chat => {
                    if (chat.id === action.payload.id) {
                        return { ...chat, title: action.payload.title };
                    }
                    return chat;
                }
            );
            return { ...state, chatHistory: updatedChats };
        case 'DELETE_CHAT_ENTRY':
            let filteredChat: Conversation[] = [];
            if (state.chatHistory) {
                filteredChat = state.chatHistory.filter(chat => chat.id !== action.payload);
            }
            useChatStore.setState({ userPrompt: "" });
            useConversationStore.setState({ conversation: null });
            return {
                ...state,
                chatHistory: filteredChat,
                followUpQuestions: [],
                activeRightPaneTab: RIGHT_PANE_TAB_SOURCES_NAME,
                expandedRightPane: false,
            };
        case 'FETCH_CHAT_HISTORY':
            return { ...state, chatHistory: action.payload };
        case 'SET_COSMOSDB_STATUS':
            return { ...state, isCosmosDBAvailable: action.payload };
        case 'FETCH_FRONTEND_SETTINGS':
            return { ...state, frontendSettings: action.payload };
        case 'SET_FEEDBACK_STATE':
            return {
                ...state,
                feedbackState: {
                    ...state.feedbackState,
                    [action.payload.answerId]: action.payload.feedback,
                },
            };
        case "SET_TENANT_SETTINGS":
            return { ...state, tenantSettings: action.payload };
        case "SET_KNOWLEDGE_DOMAIN":
            const currentDomain = state.currentKnowledgeDomain;
            return { ...state, previousKnowledgeDomain: currentDomain, currentKnowledgeDomain: action.payload };
        case "SET_IS_VALID_CURRENT_KNOWLEDGE_DOMAIN":
            return { ...state, isValidCurrentKnowledgeDomain: action.payload };
        case 'FETCH_USER':
            return { ...state, user: action.payload };
        case 'SET_ACTIVE_CITATION':
            return { ...state, activeCitation: action.payload };
        case 'TOGGLE_LEFT_PANE':
            return { ...state, expandedLeftPane: action.payload };
        case 'TOGGLE_RIGHT_PANE':
            return { ...state, expandedRightPane: action.payload };
        case "SET_HIDDEN_RIGHT_PANE":
            return { ...state, hiddenRightPane: action.payload };
        case "SET_ACTIVE_RIGHT_PANE_TAB":
            return { ...state, activeRightPaneTab: action.payload };
        case "SET_IS_SINGLE_KNOWLEDGE_DOMAIN":
            return { ...state, isSingleKnowledgeDomain: action.payload };
        case "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL":
            if (action.payload && state.isSingleKnowledgeDomain) {
                return state;
            }
            return { ...state, openedKnowledgeDomainModal: action.payload };
        case "SET_FOLLOW_UP_QUESTIONS":
            return { ...state, followUpQuestions: action.payload };
        case "SET_WEBBROWSING_ENABLED":
            return { ...state, isWebBrowsingEnabled: action.payload };
        case "SET_IS_MOBILE_VIEW":
            return { ...state, isMobileView: action.payload };
        case "FETCH_TENANT_SETTINGS":
            return { ...state, triggerFetchTenantSettings: action.payload };
        case "SET_LOADING_TENANT_SETTINGS":
            return { ...state, loadingTenantSettings: action.payload };
        case "SET_IS_ROUTE_LOADING":
            return { ...state, isRouteLoading: action.payload };
        default:
            return state;
    };
};