import styles from "./NewSession.module.css";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppStateContext } from "../../state/AppProvider";

import { useChatStore } from "../../store/Chat.store";
import { useLocationStore } from "../../store/Location.store";

import IconActionStaffReportMemo from "../../components/common/IconActionStaffReportMemo";

import QuestionInput from "../../components/QuestionInput/QuestionInput";

import { APP_ROUTE_CHAT, APP_ROUTE_REPORT_GENERATOR } from "../../constants/routeNames";

const NewSession: React.FunctionComponent = () => {
    const setLocationState = useLocationStore((state) => state.setState);

    const navigate = useNavigate();

    const appStateContext = useContext(AppStateContext);
    const tenantSettings = appStateContext?.state.tenantSettings;
    const topics = appStateContext?.state.currentKnowledgeDomain?.topics ?? [];

    const [allTopicsVisible, setAllTopicsVisible] = useState<boolean>(false);

    useEffect(
        () => {
            appStateContext?.dispatch({ type: "SET_WEBBROWSING_ENABLED", payload: false });
            appStateContext?.dispatch({ type: "FETCH_TENANT_SETTINGS", payload: true });

            appStateContext?.dispatch({ type: "SET_IS_ROUTE_LOADING", payload: false });

            return () => {
                useChatStore.setState({ userPrompt: "" });
            };
        },
        []
    );

    const setPromptAsTopic = (prompt: string) => {
        useChatStore.setState({ userPrompt: prompt });
    };

    const triggerInitialConversationMessage = (question: string) => {
        if (!question || question.length < 1) {
            return;
        }
        setLocationState({ question });
        navigate(APP_ROUTE_CHAT);
    };

    return (
        <div className={styles["new-session"]}>
            <div className={styles["new-session__top-container"]}>
                <div className={styles["new-session__top"]}>
                    <div className={styles["new-session-top__heading"]}>How can I help you today?</div>
                    <div className={styles["new-session-top__knowledge-domain-information"]}>
                        You are using the&nbsp;
                        <span className={styles["new-session-top__knowledge-domain-information--name"]}>{appStateContext?.state.currentKnowledgeDomain?.label}</span>
                        &nbsp;knowledge domain.&nbsp;
                        {
                            !appStateContext?.state.isSingleKnowledgeDomain
                            && <span className={styles["new-session-top__knowledge-domain-information--button"]} onClick={() => { appStateContext?.dispatch({ type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: true }); }}>Change</span>
                        }
                    </div>
                    <div className={styles["new-session-top__question-input"]}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Start typing anything you need"
                            disabled={false}
                            onSend={triggerInitialConversationMessage}
                            showClipButton
                        />
                    </div>
                </div>
            </div>
            <div className={styles["new-session__bottom-container"]}>
                <div className={styles["new-session__bottom"]}>
                    {
                        topics
                        && topics.length > 0
                        && <div className={styles["new-session__pre-made-prompts"]}>
                            <div className={styles["pre-made-prompts__heading"]}>Select a Pre-Built Agent:</div>
                            <div className={styles["pre-made-prompts__list"]}>
                                {
                                    (allTopicsVisible ? topics : topics.slice(0, 6))
                                        .map(
                                            topic => {
                                                return (
                                                    <div className={styles["pre-made-prompts__item"]} onClick={() => setPromptAsTopic(topic.userPrompt)} title={topic.userPrompt}>
                                                        <div className={styles["pre-made-prompts__item-title"]}>{topic.title}</div>
                                                    </div>
                                                )
                                            }
                                        )
                                }
                                {
                                    topics
                                    && topics.length > 6
                                    && <div className={styles["pre-made-prompts__item"]} onClick={() => setAllTopicsVisible(!allTopicsVisible)}>
                                        <div className={styles["pre-made-prompts__item-title"]}>{allTopicsVisible ? "Less" : "More"}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className={styles["new-session__app-shortcuts"]}>
                        {
                            tenantSettings?.visibility.reports
                            && <div className={styles["app-shortcuts-list"]}>
                                <div className={styles["app-shortcuts__heading"]}>Select a Report Output:</div>
                                <div className={styles["app-shortcuts__action"]} onClick={() => navigate(APP_ROUTE_REPORT_GENERATOR)}>
                                    <IconActionStaffReportMemo />
                                    <div className={styles["app-shortcuts__action-title"]}>Staff Report/Memo</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewSession;