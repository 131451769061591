import { createContext, useReducer, ReactNode, useEffect } from 'react';

import useMedia from 'use-media';

import { appStateReducer } from './AppReducer';

import {
    Conversation,
    CosmosDBHealth,
    historyEnsure,
    CosmosDBStatus,
    frontendSettings,
    FrontendSettings,
    Feedback,
    fetchTenantSettings,
    TenantSettings,
    KnowledgeDomain,
    UserInfo,
    getUserInfo,
    Citation,
} from '../api';

import { RIGHT_PANE_TAB_SOURCES_NAME } from "../constants/rightPane";

export interface AppState {
    user: UserInfo | null;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    frontendSettings: FrontendSettings | null;
    feedbackState: { [answerId: string]: Feedback.Neutral | Feedback.Positive | Feedback.Negative; };
    tenantSettings: TenantSettings | null;
    previousKnowledgeDomain: KnowledgeDomain | null;
    currentKnowledgeDomain: KnowledgeDomain | null;
    isValidCurrentKnowledgeDomain: boolean;
    activeCitation: Citation | null;
    expandedLeftPane: boolean;
    expandedRightPane: boolean;
    hiddenRightPane: boolean;
    activeRightPaneTab: string;
    isSingleKnowledgeDomain: boolean;
    openedKnowledgeDomainModal: boolean;
    followUpQuestions: string[];
    isWebBrowsingEnabled: boolean;
    isMobileView: boolean;
    triggerFetchTenantSettings: boolean;
    loadingTenantSettings: boolean;
    isRouteLoading: boolean;
};

export type Action =
    | { type: "SET_COSMOSDB_STATUS", payload: CosmosDBHealth }
    | { type: "UPDATE_CHAT_HISTORY", payload: Conversation | { id: string } } // API Call
    | { type: "UPDATE_CHAT_TITLE", payload: { id: string, title: string } } // API Call
    | { type: "DELETE_CHAT_ENTRY", payload: string } // API Call
    | { type: "FETCH_CHAT_HISTORY", payload: Conversation[] | null }  // API Call
    | { type: "FETCH_FRONTEND_SETTINGS", payload: FrontendSettings | null }
    | { type: "SET_FEEDBACK_STATE", payload: { answerId: string; feedback: Feedback.Positive | Feedback.Negative | Feedback.Neutral } }
    | { type: "GET_FEEDBACK_STATE", payload: string }
    | { type: "SET_TENANT_SETTINGS", payload: TenantSettings | null }
    | { type: "SET_KNOWLEDGE_DOMAIN", payload: KnowledgeDomain | null }
    | { type: "SET_IS_VALID_CURRENT_KNOWLEDGE_DOMAIN", payload: boolean }
    | { type: "FETCH_USER", payload: UserInfo | null }
    | { type: "SET_ACTIVE_CITATION", payload: Citation | null }
    | { type: "TOGGLE_LEFT_PANE", payload: boolean }
    | { type: "TOGGLE_RIGHT_PANE", payload: boolean }
    | { type: "SET_HIDDEN_RIGHT_PANE", payload: boolean }
    | { type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: string }
    | { type: "SET_IS_SINGLE_KNOWLEDGE_DOMAIN", payload: boolean }
    | { type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: boolean }
    | { type: "SET_FOLLOW_UP_QUESTIONS", payload: string[] }
    | { type: "SET_WEBBROWSING_ENABLED", payload: boolean }
    | { type: "SET_IS_MOBILE_VIEW", payload: boolean }
    | { type: "FETCH_TENANT_SETTINGS", payload: boolean }
    | { type: "SET_LOADING_TENANT_SETTINGS", payload: boolean }
    | { type: "SET_IS_ROUTE_LOADING", payload: boolean }
    ;

const initialState: AppState = {
    user: null,
    chatHistory: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured,
    },
    frontendSettings: null,
    tenantSettings: null,
    previousKnowledgeDomain: null,
    currentKnowledgeDomain: null,
    isValidCurrentKnowledgeDomain: false,
    feedbackState: {},
    activeCitation: null,
    expandedLeftPane: false,
    expandedRightPane: false,
    hiddenRightPane: false,
    activeRightPaneTab: RIGHT_PANE_TAB_SOURCES_NAME,
    isSingleKnowledgeDomain: true,
    openedKnowledgeDomainModal: false,
    followUpQuestions: [],
    isWebBrowsingEnabled: false,
    isMobileView: false,
    triggerFetchTenantSettings: false,
    loadingTenantSettings: false,
    isRouteLoading: false,
};

export const AppStateContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<Action>;
} | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);

    const isValidCurrentKnowledgeDomain = (): boolean => {
        if (!state.tenantSettings || !state.currentKnowledgeDomain) {
            return false;
        }

        const knowledgeDomains = state.tenantSettings.knowledgeDomains.filter(x => !x.disabled);
        const currentKnowledgeDomain = state.currentKnowledgeDomain;
        const isValid = knowledgeDomains
            .some(x =>
                x.indexName.trim().length > 0
                && x.indexName.trim() == currentKnowledgeDomain.indexName.trim()
            );

        return isValid;
    };

    const isMobileView = useMedia("(max-width: 768px)");

    useEffect(
        () => {
            dispatch({ type: "SET_IS_MOBILE_VIEW", payload: isMobileView });
        },
        [isMobileView]
    );

    useEffect(
        () => {
            if (
                !state.currentKnowledgeDomain
                || !state.currentKnowledgeDomain.id
                || !state.isValidCurrentKnowledgeDomain
                || !state.tenantSettings?.visibility.history
                || (
                    state.previousKnowledgeDomain
                    && state.previousKnowledgeDomain.id == state.currentKnowledgeDomain.id
                )
            ) {
                return;
            }

            const getHistoryEnsure = async () => {
                historyEnsure()
                    .then(
                        (response) => {
                            dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                        }
                    )
                    .catch(
                        () => {
                            dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured } });
                        }
                    );
            };

            getHistoryEnsure();
        },
        [state.currentKnowledgeDomain, state.isValidCurrentKnowledgeDomain, state.previousKnowledgeDomain, state.tenantSettings?.visibility.history]
    );

    useEffect(
        () => {
            const getFrontendSettings = async () => {
                frontendSettings()
                    .then(
                        (response) => {
                            dispatch({ type: "FETCH_FRONTEND_SETTINGS", payload: response as FrontendSettings });
                        }
                    )
                    .catch(
                        (err) => {
                            console.error("There was an issue fetching your data.");
                        }
                    );
            }
            getFrontendSettings();
        }, []
    );

    useEffect(
        () => {
            if (!state.triggerFetchTenantSettings || state.loadingTenantSettings) {
                return;
            }

            const getTenantSettings = async () => {
                fetchTenantSettings()
                    .then(
                        (response) => {
                            dispatch({ type: "SET_TENANT_SETTINGS", payload: response });
                            dispatch({ type: "FETCH_TENANT_SETTINGS", payload: false });
                            dispatch({ type: "SET_LOADING_TENANT_SETTINGS", payload: false });
                        }
                    );
            };
            dispatch({ type: "SET_LOADING_TENANT_SETTINGS", payload: true });
            getTenantSettings();
        },
        [state.triggerFetchTenantSettings, state.loadingTenantSettings]
    );

    useEffect(
        () => {
            const fetchUser = async () => {
                getUserInfo()
                    .then(
                        (response: UserInfo[]) => {
                            if (!response) {
                                return;
                            }
                            dispatch({ type: "FETCH_USER", payload: response[0] });
                        }
                    );
            };
            fetchUser();
        },
        []
    );

    useEffect(
        () => {
            const knowledgeDomains = state.tenantSettings?.knowledgeDomains ?? [];
            const activeDomains = knowledgeDomains.filter(x => !x.disabled);
            if (activeDomains.length > 0) {
                const onlyOneDomainAvailable: boolean = activeDomains.length == 1;
                dispatch({ type: "SET_IS_SINGLE_KNOWLEDGE_DOMAIN", payload: onlyOneDomainAvailable });
                if (!state.currentKnowledgeDomain) {
                    dispatch({ type: "SET_KNOWLEDGE_DOMAIN", payload: activeDomains[0] });
                } else {
                    const updatedKnowledgeDomain = knowledgeDomains.find(x => x.id == state.currentKnowledgeDomain?.id) ?? null;
                    dispatch({ type: "SET_KNOWLEDGE_DOMAIN", payload: updatedKnowledgeDomain });
                }
            }
        },
        [state.tenantSettings?.knowledgeDomains]
    );

    useEffect(
        () => {
            const isCurrentValid = isValidCurrentKnowledgeDomain();
            dispatch({ type: "SET_IS_VALID_CURRENT_KNOWLEDGE_DOMAIN", payload: isCurrentValid });
        },
        [state.currentKnowledgeDomain]
    );

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    );
};
