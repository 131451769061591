import styles from "./KnowledgeDomainButton.module.css";

import { useContext } from "react";

import { AppStateContext } from "../../state/AppProvider";

import IconKnowledgeDomainDefault from "../common/IconKnowledgeDomainDefault";
import IconKnowledgeDomainButtonChevronSort from "../common/IconKnowledgeDomainButtonChevronSort";

interface KnowledgeDomainButtonProps {
    openModal: () => void;
};

const KnowledgeDomainButton: React.FunctionComponent<KnowledgeDomainButtonProps> = ({ openModal }) => {
    const appStateContext = useContext(AppStateContext);

    return (
        <div className={styles["knowledge-domain-button"]} onClick={openModal}>
            <div className={styles["knowledge-domain-button__content-container"]}>
                <div className={styles["knowledge-domain-button__icon-container"]}>
                    {
                        appStateContext?.state.currentKnowledgeDomain?.iconUrl?.length ?? 0 > 0 ?
                            <img className={styles["knowledge-domain-button__icon"]} src={appStateContext?.state.currentKnowledgeDomain?.iconUrl} width={24} height={24} />
                            : <IconKnowledgeDomainDefault />
                    }
                </div>
                <div className={styles["knowledge-domain-button__label"]}>{appStateContext?.state.currentKnowledgeDomain?.label}</div>
            </div>
            {
                !appStateContext?.state.isSingleKnowledgeDomain
                && <IconKnowledgeDomainButtonChevronSort className={styles["knowledge-domain-button__chevron"]} />
            }
        </div>
    );
};

export default KnowledgeDomainButton;