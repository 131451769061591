import styles from "./FileUpload.module.css";

import { useContext, useEffect, useRef, useState } from "react";

import { AppStateContext } from "../../state/AppProvider";

import { useConversationStore } from "../../store/Conversation.store";
import { useUploadedFileStore } from "../../store/UploadedFiles.store";

import { UploadedFile, UploadedFilesLoadingState, fetchUploadedFiles, UploadedFileOrigin, KnowledgeDomain, ConversationType } from "../../api";

import IconTooltipInfo from "../common/IconTooltipInfo";
import IconFileUploaderAddFile from "../common/IconFileUploaderAddFile";

import FileUploadModal from "./FileUploadModal";
import FileUploadList from "./FileUploadList";

import { DefaultButton, IButtonStyles, Spinner, SpinnerSize } from "@fluentui/react";

const renderFileUploadContents = (
    fileListState: UploadedFilesLoadingState,
    conversationType: ConversationType | undefined,
    conversationId: string | undefined
) => {
    switch (fileListState) {
        case UploadedFilesLoadingState.Empty:
        case UploadedFilesLoadingState.Success:
            const elementClassName: string = `${styles["file-uploader__list"]} ${fileListState == UploadedFilesLoadingState.Empty ? styles["file-uploader__list--failed"] : ""}`;
            const uploadedFileOrigin: UploadedFileOrigin = conversationType == ConversationType.Report ? UploadedFileOrigin.Report : UploadedFileOrigin.Chat;
            return (
                <div className={elementClassName}>
                    {
                        fileListState == UploadedFilesLoadingState.Empty && `There are no temporary files being used in this output.`
                    }
                    <FileUploadList
                        filesOrigin={uploadedFileOrigin}
                        filterByIncludingId={conversationId}
                    />
                </div>
            );
        case UploadedFilesLoadingState.Loading:
            return (
                <div className={`${styles["file-uploader__list"]} ${styles["file-uploader__list--loading"]}`}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        case UploadedFilesLoadingState.Fail:
        default:
            return (
                <div className={`${styles["file-uploader__list"]} ${styles["file-uploader__list--failed"]}`}>
                    There was an error fetching the files uploaded to your index.
                </div>
            );
    };
};

const FileUpload: React.FunctionComponent = () => {
    const appStateContext = useContext(AppStateContext);

    const currentConversation = useConversationStore((state) => state.conversation);

    const [listLoadingState, setListLoadingState] = useState(UploadedFilesLoadingState.Empty);
    const uploadedFiles = useUploadedFileStore((state) => state.files);

    const abortControllerRef = useRef(new AbortController());
    const previousKnowledgeDomainRef = useRef<KnowledgeDomain | null>();

    useEffect(
        () => {
            if (listLoadingState == UploadedFilesLoadingState.Empty && uploadedFiles && uploadedFiles.length > 0) {
                setListLoadingState(UploadedFilesLoadingState.Success);
            } else if (listLoadingState == UploadedFilesLoadingState.Success && (!uploadedFiles || uploadedFiles.length < 1)) {
                setListLoadingState(UploadedFilesLoadingState.Empty);
            } else {
                return;
            }
        },
        [listLoadingState, uploadedFiles]
    );

    const fetchUploadedFilesToKnowledgeDomainContainer = async () => {
        setListLoadingState(UploadedFilesLoadingState.Loading);
        const controller = abortControllerRef.current;
        fetchUploadedFiles(controller.signal, appStateContext?.state.currentKnowledgeDomain?.containerName!, appStateContext?.state.currentKnowledgeDomain?.indexName!)
            .then(
                (response: UploadedFile[]) => {
                    if (!response || response.length < 1) {
                        setListLoadingState(UploadedFilesLoadingState.Empty);
                        return;
                    }

                    useUploadedFileStore.setState({ files: response });

                    setListLoadingState(UploadedFilesLoadingState.Success);
                }
            )
            .catch(
                () => {
                    if (controller.signal.aborted) {
                        return;
                    }
                    setListLoadingState(UploadedFilesLoadingState.Fail);
                }
            );
    };

    useEffect(
        () => {
            abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();

            const previous = previousKnowledgeDomainRef.current;
            previousKnowledgeDomainRef.current = appStateContext?.state.currentKnowledgeDomain;

            if (
                previous
                && (
                    previous.containerName != appStateContext?.state.currentKnowledgeDomain?.containerName
                    || previous.indexName != appStateContext?.state.currentKnowledgeDomain?.indexName
                )
            ) {
                useUploadedFileStore.setState({ files: [] });
            }

            if (
                appStateContext?.state.isValidCurrentKnowledgeDomain
                && (!uploadedFiles || uploadedFiles.length < 1)
            ) {
                fetchUploadedFilesToKnowledgeDomainContainer();
            }

            return () => {
                abortControllerRef.current.abort();
            };
        },
        [appStateContext?.state.currentKnowledgeDomain, appStateContext?.state.isValidCurrentKnowledgeDomain]
    );

    const [isOpenUploadModal, setIsOpenUploadModal] = useState<boolean>(false);

    const onUploadFiles = (files: UploadedFile[]): UploadedFile[] => {
        // Associate the uploaded file to the report
        // Injecting the report Id to the file name to achieve this
        const reportId = currentConversation?.id;
        const renamedFiles: UploadedFile[] = files.map(
            file => {
                const modifiedFile = new File([file.file!], `${reportId}_${file.name}`);
                const resultFile = { ...file, name: modifiedFile.name, file: modifiedFile };
                return resultFile;
            }
        );
        return renamedFiles;
    };

    const buttonStyles: Partial<IButtonStyles> = {
        root: { height: "30px", borderRadius: "8px", border: "1px solid rgba(145, 158, 171, 0.32)", backgroundColor: "#ffffff", padding: "0px 0px", marginLeft: "auto" },
        flexContainer: { gridGap: "4px" },
        textContainer: { flexGrow: "initial" },
        label: { fontWeight: 700, fontSize: "13px", lineHeight: "22px", color: "#1c252e" }
    };

    return (
        <div className={styles["file-uploader"]}>
            <div className={styles["file-uploader__header"]}>
                <div className={styles["file-uploader__header-text"]}>Temporary files:</div>
                <IconTooltipInfo />
                <DefaultButton
                    text="Upload"
                    styles={buttonStyles}
                    onRenderIcon={() => { return (<IconFileUploaderAddFile />); }}
                    onClick={() => { setIsOpenUploadModal(true); }}
                    disabled={
                        listLoadingState == UploadedFilesLoadingState.Loading
                        || listLoadingState == UploadedFilesLoadingState.Fail
                        || !appStateContext?.state.isValidCurrentKnowledgeDomain
                    }
                />
            </div>
            {renderFileUploadContents(listLoadingState, currentConversation?.type, currentConversation?.id)}
            <FileUploadModal
                isOpen={isOpenUploadModal}
                setOpen={(isOpen) => setIsOpenUploadModal(isOpen)}
                filesOrigin={currentConversation?.type == ConversationType.Report ? UploadedFileOrigin.Report : UploadedFileOrigin.Chat}
                onUploadFiles={currentConversation?.type == ConversationType.Report && currentConversation.id ? onUploadFiles : undefined}
            />
        </div>
    );
};

export default FileUpload;